<template>
    <v-row
        justify="center"
    >        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="900"                
            >
                <v-card-title
                    class="d-flex white--text  py-2 px-0 primary"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-light text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkSheet/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson/>
                    <lesson-admin></lesson-admin>  
                    <div 
                        class="d-flex flex-column justify-center align-center"
                        v-show="loading"
                    >    
                        <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"                            
                            color="primary"
                            v-show="loading"
                        ></v-progress-circular>
                        <div 
                            class="primary--text mt-4"
                            v-show="loading"
                        >{{ progressText }}</div>
                    </div> 
                </v-overlay>
               
            </v-card>
        </v-col>
        
         <v-bottom-sheet 
            v-model="sheet" 
            inset
            persistent
        >
            <v-row align="center" justify="center">
                <v-alert
                    :value="error"
                    prominent
                    type="error"
                    transition="scale-transition"
                    dense                        
                >
                    <v-row align="center">
                        <v-col>{{ errorMessage }}</v-col>
                    </v-row>
                </v-alert>
            </v-row>

            <v-sheet class="text-center px-10" height="200px">
                <v-row justify="end">
                    <div>
                        <v-btn
                            class="mt-1"
                            text
                            outlined
                            color="red"
                            @click="closeBottomSheet"
                        >close</v-btn>
                    </div>
                </v-row>                
                <v-form ref="form">
                    <v-textarea                                       
                        label="Subject Teacher Comments"                        
                        hint="Max 300 Characters"
                        rows="3"                                           
                        counter                                             
                        no-resize
                        v-model="customComment"
                        autofocus                        
                        persistent-hint
                        maxlength="300"                                                                                                                                        
                    >                        
                    </v-textarea>
                </v-form>                    
            </v-sheet>
        </v-bottom-sheet>
    </v-row>    
</template>

<script>
import Header from './EnterMarksHeader'
import Lesson from './EnterMarksLesson'
import MarkSheet from './EnterMarksSheet'
import LessonAdmin from './EnterMarksLessonAdmin'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'EnterMarks',
    components: {
        Header,
        Lesson,
        LessonAdmin,
        MarkSheet,
    },

    created: function () {
        this.initialize();
    },

    watch: {
        index: {
            handler (val) {                
                this.customComment = this.table2Records[val].custom_comment;
            }
        }       
    },

    computed: {
        ...mapGetters({
            overlay: 'termReports/getMarkSheetOverlay',            
            loading: 'termReports/getMarkSheetLoading',
            totalStudents: 'termReports/getSubjectStudentsTotal',
            lessonSelected: 'termReports/getLessonSelected',
            progressText: 'termReports/getProgressText',
            sheet: 'termReports/getCommentSheetEnterMarks',
            index: 'termReports/getSelectedTable2RecordIndex',
            table2Records: 'termReports/getTable2Records',  
        }),        
    },

    data: () => ({
        absolute: true,             
        userTimetable: [],
        bottomSheetModel: null,
        errorMessage: null,
        error: false,
        customComment: null,
    }),

    methods: {
        ...mapMutations({            
            enterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setMarkSheetOverlay',
            setLoading: 'termReports/setMarkSheetLoading',
            expand: 'termReports/setExpandLessonOptions',           
            setTable2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
            setOverlayLessons: 'termReports/setOverlayLessons',
            setCommentSheet: 'termReports/setCommentSheetEnterMarks',
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setMainMenuButton: 'termReports/setMainMenuButton',
            setFormClasses: 'termReports/setFormClasses',
        }),

        ...mapActions({
            getFormClasses: 'termReports/getFormClasses',
            getCodedComments: 'termReports/getCodedCommentsList',
        }),

        async initialize () {
            console.log('initializing enter marks...')
            this.setMainMenuButton(true);
            this.$nextTick(() => {
                this.setOverlay(true);
                this.setLoading(true);
            })
            const { data } = await  this.getFormClasses();
            await this.getCodedComments();
            this.mapFormClasses(data);
        },

        mapFormClasses (data) {
            let formClasses = [];
            let form1Classes = [];
            let form2Classes = [];
            let form3Classes = [];
            let form4Classes = [];
            let form5Classes = [];
            let form6Classes = [];            
            data.forEach(record => {
                switch(record.form_level){
                    case 1:
                    form1Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 2:
                    form2Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 3:
                    form3Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 4:
                    form4Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 5:
                    form5Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 6:
                    form6Classes.push({
                        title: record.class_id
                    });
                    break;
                }                
            });
            formClasses.push({
                title: 'Form 1',
                action: 'mdi-school',                
                items: form1Classes
            });
            formClasses.push({
                title: 'Form 2',
                action: 'mdi-school',                
                items: form2Classes
            });
            formClasses.push({
                title: 'Form 3',
                action: 'mdi-school',                
                items: form3Classes
            });
            formClasses.push({
                title: 'Form 4',
                action: 'mdi-school',                
                items: form4Classes
            });
            formClasses.push({
                title: 'Form 5',
                action: 'mdi-school',                
                items: form5Classes
            });
            formClasses.push({
                title: 'Form 6',
                action: 'mdi-school',                
                items: form6Classes
            });            
            this.setFormClasses(formClasses);
        },

        setLessonSelected(e){
            this.loading = true;
            console.log(e);
        },      

        closeMarkSheet(){
            this.setTable2Records([]);
            this.setOverlayLessons(true);
            this.setOverlay(true);            
            this.setStudentName(''); 
        },

        closeBottomSheet () {            
            this.table2Records[this.index].custom_comment = this.customComment;
            this.setCommentSheet(false);
        },

        
    }
}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: "1rem";
    }
</style>